import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Foot from '../components/footer'

import '../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'

const HoodieHackingPage = () => {
    return (<Layout languageSwitchLink="/en/hoodiehacking">
        <SEO title="Hoodie Hacking"/>
        <Banner class_suffix="deep"/>
        <center>
            <h1 style={{ fontSize: '600%' }}>Hoodie Hacking !</h1>
            <div className="about-container">
                <p style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
                    Bienvenue sur la track hoodie hacking de PolyCyber 😎
                    <br />
                    <br />Si tu n'es pas encore un véritable hacker, tu peux retourner à l'accueil ici : <a href="/">Accueil</a>
                    <br />Sinon, pour les vrais hackers, trouve quelle est la différence entre cette photo et ton hoodie ...
                </p>
            </div>
            <img src='/hoodie_hacking_stuff/hoodie_hacking.png' style={{ width:'80%'}}/>
        </center>
        <Foot></Foot>
    </Layout>)
}
export default HoodieHackingPage
